<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img
            v-if="me.image"
            :src="$store.state.urlBackend + '/' + me.image"
            class="c-avatar-img "
          />
          <div v-else class="client-name-letter" :style="'background: ' + clientColor(me.name)">{{clientName(me.name)}}</div>
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>{{me.name}}</strong>
    </CDropdownHeader>
    <!-- <CDropdownItem>
      <CIcon name="cil-bell"/> Updates
      <CBadge color="info" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-envelope-open" /> Messages
      <CBadge color="success" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-task" /> Tasks
      <CBadge color="danger" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-comment-square" /> Comments
      <CBadge color="warning" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem> -->
    <!-- <CDropdownHeader
      tag="div"
      class="text-center"
      color="light"
    >
      <strong>Settings</strong>
    </CDropdownHeader> -->
    <CDropdownItem>
      <CIcon name="cil-user" /> Профиль
    </CDropdownItem>
    <!-- <CDropdownItem>
      <CIcon name="cil-settings" /> Settings
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-dollar" /> Payments
      <CBadge color="secondary" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-file" /> Projects
      <CBadge color="primary" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownDivider/>
    <CDropdownItem>
      <CIcon name="cil-shield-alt" /> Lock Account
    </CDropdownItem> -->
    <CDropdownItem @click="logOut">
      <CIcon name="cil-lock-locked"  /> Выйти
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: 'TheHeaderDropdownAccnt',
  props: [
    'user'
  ],
  data () {
    return {
      itemsCount: 42
    }
  },
  mounted(){
    
  },
  methods: {
    clientName(name){
      // let matches = name.match(/\b(\w)/g);
      // let acronym = matches.join('');
      let username = name.split(' ').map(function(item){return item[0]}).join('');
      if(username[1]){
        return username[0]+username[1];
      }else if(username[0]){
        return username[0];
      }
      return 'EE';
    },
    clientColor(name){
      name = name.toUpperCase();
      let char = name.charCodeAt(0);

      let color = '#4CAF50';
      if(char > 64 && char < 77){
        color = '#6EC9CB';
      }else if(char > 76 && char < 91){
        color = '#FAA774';
      }else if(char > 1039 && char < 1051){
        color = '#A695E7';
      }else if(char > 1050 && char < 1060){
        color = '#4CAF50';
      }else if(char > 1059 && char < 1072){
        color = '#E17076';
      }

      return color;
    },
    logOut(){
      localStorage.auth = false;
      localStorage.token = null;
      this.$router.push('/pages/login');
    }
  },
  computed: {
    me () {
      return JSON.parse(localStorage.me)
    },
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
  .client-name-letter{
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #eee;
    text-transform: uppercase;
    font-size:  16px;
    font-weight: bold;
    background: #4CAF50;
    margin: 0 !important;
  }
</style>
