export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Главная',
        to: '/dashboard',
        icon: 'cil-speedometer'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Международные заказы',
        to: '/orders-international',
        icon: 'cil-flight-takeoff'

      },
      {
        _name: 'CSidebarNavItem',
        name: 'Местные заказы',
        to: '/orders-local',
        icon: 'cil-truck'
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Клиенты',
        to: '/clients',
        icon: 'cil-people',
        items: [
          {
            name: 'Клиенты',
            to: '/clients'
          },
          {
            name: 'Адреса',
            to: '/clients/address'
          },
          {
            name: 'Договора',
            to: '/clients/contracts'
          },
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Отслеживание водителей',
        to: '/tracking-drivers',
        icon: 'cib-openstreetmap'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Рассылки']
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Email Рассылка',
        route: '/mailing',
        icon: 'cil-send',
        items: [
          {
            name: 'Группы',
            to: '/mailing/email-groups'
          },
          {
            name: 'Абоненты',
            to: '/mailing/email-abonents'
          },
        ]
      },
    ]
  }
]
